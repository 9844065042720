@import '~ethos-design-system/src/components/Media/Media.scss';

$overlaySize: 250px;
$halfOverlaySize: 125px;

$overlay: 644px;
$mobileOverlay: 60px;

.highlightNumbersWrapper {
  z-index: 1;
  border: 1px solid rgba(39, 39, 39, 0.2);
  border-radius: 24px;

  flex-direction: column;
  padding: 60px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 20px;
  margin-bottom: -200px;

  @include for-laptop-and-up {
    flex-direction: row;
    gap: 40px;
    height: 245px;
    margin-bottom: -130px;
    padding: 20px;
    padding-left: 110px;
    padding-right: 110px;
  }

  @include for-phone-only {
    padding-right: 35px;
    padding-left: 35px;
  }

  @media (min-width: 900px) and (max-width: 1040px) {
    gap: 20px;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.highlightedNumber {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: 'Cambon';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 120%;
  letter-spacing: -0.02em;
  @include for-phone-only {
    font-size: 40px;
  }
}

.highlightedNumberAppend {
  padding-left: 5px;
  font-family: 'Cambon';
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;
  @include for-tablet-and-up {
    padding-bottom: 5px;
  }
}

.highlightedNumberPrepend {
  display: flex;
  align-items: center;
  margin-top: 20px;
  @include for-phone-only {
    margin-top: 10px;
  }
}

.highlightedText {
  font-family: 'Theinhardt2';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  width: 100%;
  display: flex;
  justify-content: center;
  @include for-phone-only {
    font-size: 20px;
  }
}

.bannerWrapper {
  width: 100vw;
  border-radius: 0px 0px 0px 100px;
  background: linear-gradient(180deg, #dbede5 -31.92%, #f9ffdf 128.08%);
  padding-top: 250px;

  @include for-laptop-and-up {
    border-radius: 0px 0px 0px 200px;
    padding-top: 160px;
    height: 644px;
  }
}

.paddingCropper {
  .bannerWrapper {
    padding-top: 52px;
    @include for-laptop-and-up {
      height: auto;
    }
    @include for-phone-only {
      padding-top: 48px;
    }
  }
}

.neverMobile {
  @include for-phone-only {
    display: none;
  }
}

.bannerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  @include for-phone-only {
    border-radius: 0px 0px 0px 100px;
  }
}
.bannerImageWrapper {
  position: relative;
}

.bannerContentWrapper {
  @include for-laptop-and-up {
    width: 340px;
    margin-left: 100px;
    margin-right: 20px;
    text-align: start;
  }
  border-radius: 0px 0px 0px 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
}

.bannerButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 64px;
  gap: 8px;

  width: 216px;
  height: 56px;

  background: #056257;
  border-radius: 8px;
  color: white;
  cursor: pointer;

  &:hover,
  &:active {
    opacity: 0.85;
  }
}

.bannerButtonWrapper {
  padding-top: 16px;
  padding-bottom: 50px;
  @include for-laptop-and-up {
    align-self: start;
  }
}

.greenBlob {
  animation: pulse-green 2s infinite;
  background: #00cd46;
  border-radius: 50%;
  box-shadow: 0 0 0 0 #00cd46;
  height: var(--Space-8);
  margin: 3px var(--Space-12) 3px 0;
  transform: scale(1);
  width: var(--Space-8);
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 205, 70, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 205, 70, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 205, 70, 0);
  }
}

.trustageBannerWrapper {
  background: linear-gradient(180deg, #dbede5 -31.92%, #edf6f2 128.08%);
  padding-top: 0;
  border-radius: 0;
  @include for-laptop-and-up {
    padding-top: 183px !important;
    border-radius: 0px 0px 0px 100px;
  }
}

.trustageBannerButtonWrapper {
  padding-bottom: 50px;
  @include for-laptop-and-up {
    align-self: start;
    padding-bottom: 135px;
  }
}

.trustageBannerImage {
  @include for-phone-only {
    border-radius: 0;
  }
}
