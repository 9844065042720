@import '~ethos-design-system/src/components/Media/Media.scss';

.slideshow {
  margin: 0 auto;
  overflow: hidden;
}

.slideshow .innerSlideShow {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.slideshowSlider {
  white-space: nowrap;
}

.slide {
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 40px;
  margin-bottom: 24px;
  @include for-phone-only {
    text-align: center;
  }
}

.slideshowTabs {
  text-align: center;
  display: flex;
  padding-bottom: 20px;
  gap: 2px;
  margin: auto;
  width: 95%;

  @include for-tablet-and-up {
    width: 350px;
  }
  @include for-phone-only {
    width: 271px;
  }
}

.slideshowTab {
  height: 3px;
  border-radius: 16px;
  background-color: #dbede5;
  flex-basis: 100%;
}

.slideshowTabHover {
  &:hover {
    cursor: pointer;
  }
}

.slideshowTab .progressBar {
  width: 0%;
  &.active {
    height: 4px;
    width: 100%;
    background-color: #056257;
  }
}

.textblock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: auto;
  max-width: 380px;
}

.video {
  max-width: 331.43px;
  aspect-ratio: 9/16;
  @include for-tablet-only {
    max-width: 200px;
  }
}

.inactiveTitle {
  display: none;
}

.chapterTitle {
  font-family: sans-serif !important;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.description {
  @extend .title;
  font-size: 32px !important;
  line-height: 140% !important;

  max-width: 380px;
  width: auto;
  @include for-tablet-and-up {
    width: 380px;
  }
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 64px;
  cursor: pointer;
  gap: 8px;

  width: 257px;
  height: 56px;

  background: #056257;
  border-radius: 8px;
  &:hover,
  &:active {
    opacity: 0.85;
  }

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
}

.all {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  padding: 0 8px;
  margin: auto;

  @include for-tablet-and-up {
    display: grid;
    grid-template-columns: 200px auto;
    column-gap: 60px;
    row-gap: 24px;
    padding-bottom: var(--Space-32);
    padding-top: 80px;
    .videoWrapper {
      grid-row: span 2;
    }
    .textblock {
      align-self: self-end;
    }
    .button {
      align-self: self-start;
    }
  }
  @include for-laptop-and-up {
    column-gap: 130px;
    grid-template-columns: 330px auto;
  }

  @include for-phone-only {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    padding: 48px 20px;
    height: auto;
    gap: 16px;
    .videoWrapper {
      padding-bottom: 32px;
    }
    .textblock {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      padding-bottom: 8px;
      order: -1;

      width: auto;

      .title {
        align-items: center;
        text-align: center;
      }
      .description {
        align-items: center;
        text-align: center;
      }
    }
  }
}
